/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"

import { SITE_URL } from "../cms/env"
import Header from "./header"
import Footer from "./footer"
// import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main
            css={css`
              margin: 0 auto;
              max-width: 1000px;
              padding: 0px 3px 1.45rem;
              padding-top: 0;
              min-height: calc(100vh - 80px - 85px);
            `}
          >
            {children}
          </main>
          <Footer host={new URL(SITE_URL).host}></Footer>
        </>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
