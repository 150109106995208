import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { css } from "@emotion/core"
import styled from "@emotion/styled"
import mediaQuery from "styled-media-query"

import Sticky from "react-sticky-el"

const mediaSmallMobile = mediaQuery.lessThan("small")

const Menu = styled("div")`
  width: 100%;
  margin: 0px 0px;
  background-color: #393e46;
  text-align: center;
  color: #eeeeee;
  border-bottom: solid 6px;
  border-bottom-color: #00adb5;
  // overflow: auto;
  display: table;
  font-size: 80%;
  bottom: 0px;
`

const partlyActive = className => ({
  isCurrent,
  isPartiallyCurrent,
  href,
  location,
}) => {
  const isRealCurrent =
    href === `/` && !location.pathname.match(/^\/\d+$/g)
      ? isCurrent
      : isPartiallyCurrent
  return {
    className: className + (isRealCurrent ? ` is-current` : ``),
  }
}
const PartlyActiveLink = ({ className, ...rest }) => (
  <span>
    <Link getProps={partlyActive(className)} {...rest} />
  </span>
)
const MenuItem = styled(PartlyActiveLink)`
  display: table-cell;
  padding: 5px 8px;
  text-decoration: none;
  font-size: 1em;
  color: #ffffff;
  &.is-current {
    background-color: #00adb5;
    color: #393e46;
  }
`

const Header = ({ siteTitle }) => (
  <header
    css={css`
      background: #393e46;
      margin-bottom: 1.45rem;
      height: 80px;
      ${mediaSmallMobile`
        font-size: 80%;
      `}
      position: relative;
    `}
  >
    <div
      css={css`
        margin: 0 0;
        padding: 1rem 1.0875rem;
      `}
    >
      <h1
        css={css`
          margin: 0;
          width: 100%;
          text-align: center;
          font-size: 1.4em;
          text-shadow: 0px 4px 0px #222831;
          ${mediaSmallMobile`
            font-size: 1.75em;
          `}
        `}
        className="font-pixel"
      >
        <Link
          to="/"
          css={css`
            color: #eeeeee;
            text-decoration: none;
          `}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
    <Sticky mode="top">
      <Menu className="font-pixel">
        <MenuItem to="/">Top</MenuItem>
        <MenuItem to="/about">About</MenuItem>
        <MenuItem to="/blog">Blog</MenuItem>
        <MenuItem to="/tech">Tech</MenuItem>
        <MenuItem to="/archives">Archives</MenuItem>
      </Menu>
    </Sticky>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
