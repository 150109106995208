// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { css } from "@emotion/core"
// import styled from "@emotion/styled"

const Footer = props => {
  return (
    <footer
      css={css`
        height: 60px;
        padding: 10px;
      `}
    >
      <p
        className="font-pixel"
        css={css`
          font-size: x-small;
          text-align: center;
          margin: 15px auto;
        `}
      >
        ©{props.host}
      </p>
    </footer>
  )
}

Footer.propTypes = {
  host: PropTypes.string,
}

Footer.defaultProps = {
  host: ``,
}

export default Footer
