const SITE_ENV = process.env.GATSBY_SITE_ENV || "development"
const SITE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : process.env.GATSBY_SITE_URL

const IS_PRODUCTION = SITE_ENV === "production"
const IS_DEVELOPMENT = SITE_ENV === "development"

export { SITE_ENV, SITE_URL, IS_PRODUCTION, IS_DEVELOPMENT }
